var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-md-12 col-lg-12" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "설비 기본정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [_c("q-btn-group", { attrs: { outline: "" } })],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "설비Tag",
                              name: "equipmentNo",
                            },
                            model: {
                              value: _vm.data.equipmentNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentNo", $$v)
                              },
                              expression: "data.equipmentNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "설비명",
                              name: "equipmentName",
                            },
                            model: {
                              value: _vm.data.equipmentName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentName", $$v)
                              },
                              expression: "data.equipmentName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-equip-class", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "설비유형",
                              name: "equipmentTypeCd",
                            },
                            model: {
                              value: _vm.data.equipmentTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "equipmentTypeCd", $$v)
                              },
                              expression: "data.equipmentTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-plant", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-cost-center", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              disabled: true,
                              editable: _vm.editable,
                              label: "코스트센터",
                              name: "costCenterCd",
                            },
                            model: {
                              value: _vm.data.costCenterCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "costCenterCd", $$v)
                              },
                              expression: "data.costCenterCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-process", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "단위공정",
                              multiple: "multiple",
                              name: "subProcessCd",
                            },
                            model: {
                              value: _vm.data.subProcessCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "subProcessCd", $$v)
                              },
                              expression: "data.subProcessCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-9" },
                        [
                          _c("c-dept-multi", {
                            attrs: {
                              disabled: true,
                              label: "관리부서",
                              parentCheckDepts: _vm.data.managementDepts,
                              plantCd: _vm.data.plantCd,
                              name: "managementDepts",
                            },
                            model: {
                              value: _vm.data.managementDepts,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "managementDepts", $$v)
                              },
                              expression: "data.managementDepts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "수량",
                              name: "amount",
                              type: "number",
                            },
                            model: {
                              value: _vm.data.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "amount", $$v)
                              },
                              expression: "data.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치관련 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-func-location", {
                            attrs: {
                              type: "edit",
                              plantCd: _vm.data.plantCd,
                              editable: _vm.editable,
                              disabled: true,
                              label: "기능위치",
                              name: "funcLocationCd",
                            },
                            model: {
                              value: _vm.data.funcLocationCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "funcLocationCd", $$v)
                              },
                              expression: "data.funcLocationCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              label: "설치일",
                              disabled: true,
                              name: "installDate",
                            },
                            model: {
                              value: _vm.data.installDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "installDate", $$v)
                              },
                              expression: "data.installDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "설치업체",
                              name: "installVendor",
                            },
                            model: {
                              value: _vm.data.installVendor,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "installVendor", $$v)
                              },
                              expression: "data.installVendor",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              label: "가동일",
                              disabled: true,
                              name: "opertationDate",
                            },
                            model: {
                              value: _vm.data.opertationDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "opertationDate", $$v)
                              },
                              expression: "data.opertationDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "설치상태 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "검사합격번호",
                              name: "checkPassNo",
                            },
                            model: {
                              value: _vm.data.checkPassNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "checkPassNo", $$v)
                              },
                              expression: "data.checkPassNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              label: "합격일",
                              disabled: true,
                              name: "checkPassDate",
                            },
                            model: {
                              value: _vm.data.checkPassDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "checkPassDate", $$v)
                              },
                              expression: "data.checkPassDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: _vm.editable,
                              codeGroupCd: "WO_EQUIP_STOP_CD",
                              disabled: true,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "discardFlag",
                              label: "설비상태",
                            },
                            model: {
                              value: _vm.data.discardFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "discardFlag", $$v)
                              },
                              expression: "data.discardFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "설비상태 변경일",
                              name: "discardDate",
                            },
                            model: {
                              value: _vm.data.discardDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "discardDate", $$v)
                              },
                              expression: "data.discardDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-lg-6" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: {
                      title: "점검관련 정보",
                      topClass: "topcolor-blue",
                    },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              isFlag: true,
                              label: "예방점검 여부",
                              name: "operFlag",
                            },
                            model: {
                              value: _vm.data.operFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "operFlag", $$v)
                              },
                              expression: "data.operFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                              disabled: true,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "selfCycleCd",
                              label: "예방점검주기",
                            },
                            model: {
                              value: _vm.data.selfCycleCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "selfCycleCd", $$v)
                              },
                              expression: "data.selfCycleCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              isFlag: true,
                              label: "PM여부",
                              name: "pmFlag",
                            },
                            model: {
                              value: _vm.data.pmFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pmFlag", $$v)
                              },
                              expression: "data.pmFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              codeGroupCd: "MDM_EQUIP_CYCLE_CD",
                              disabled: true,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "pmCycleCd",
                              label: "PM주기",
                            },
                            model: {
                              value: _vm.data.pmCycleCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pmCycleCd", $$v)
                              },
                              expression: "data.pmCycleCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-multi-select", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              codeGroupCd: "REGULATION_BILL_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "관련 법규",
                              name: "relatedLaws",
                              maxValues: "3",
                            },
                            model: {
                              value: _vm.data.relatedLaws,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "relatedLaws", $$v)
                              },
                              expression: "data.relatedLaws",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-md-6 col-lg-6",
                staticStyle: { "margin-top": "14px" },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: false,
                    isPhoto: true,
                    label: "설비 사진",
                  },
                }),
              ],
              1
            ),
            _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
            _c("div", { staticClass: "popup-bottom-bar" }, [
              _c(
                "div",
                { staticClass: "popup-bottom-bar-btngroup" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              flat: true,
                              label: "출력",
                              icon: "save_alt",
                            },
                            on: { btnClicked: _vm.btnQrCode },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "popup-bottom-bar-close" },
                [
                  _c("q-btn", {
                    attrs: { flat: "", color: "gray", icon: "arrow_back" },
                    on: { click: _vm.closePopUps },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }