<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="설비 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn label="출력" v-if="editable" icon="save_alt" @btnClicked="btnQrCode" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" /> -->
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="설비Tag"
                  name="equipmentNo"
                  v-model="data.equipmentNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="설비명"
                  name="equipmentName"
                  v-model="data.equipmentName">
                </c-text>
              </div>
              <div class="col-6">
                <c-equip-class
                  :editable="editable"
                  :disabled="true"
                  label="설비유형"
                  name="equipmentTypeCd"
                  v-model="data.equipmentTypeCd">
                </c-equip-class>
              </div>
              <div class="col-6">
                <c-plant
                  :editable="editable"
                  :disabled="true"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-6">
                <c-cost-center
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :disabled="true"
                  :editable="editable" 
                  label="코스트센터" 
                  name="costCenterCd" 
                  v-model="data.costCenterCd" />
              </div>
              <div class="col-6">
                <c-process
                  :editable="editable"
                  :disabled="true"
                  label="단위공정"
                  multiple="multiple"
                  name="subProcessCd"
                  v-model="data.subProcessCd">
                </c-process>
              </div>
              <div class="col-9">
                <c-dept-multi :disabled="true" label="관리부서" :parentCheckDepts="data.managementDepts" :plantCd="data.plantCd" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="수량"
                  name="amount"
                  type="number"
                  v-model="data.amount">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-func-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable" 
                  :disabled="true"
                  label="기능위치" 
                  name="funcLocationCd" 
                  v-model="data.funcLocationCd" />
                <!-- <c-text
                  :editable="editable"
                  label="설비위치"
                  name="equipmentLocation"
                  v-model="data.equipmentLocation">
                </c-text> -->
              </div>
              <div class="col-6">
                <c-datepicker
                  label="설치일"
                  :disabled="true"
                  name="installDate"
                  v-model="data.installDate"
                />  
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="설치업체"
                  name="installVendor"
                  v-model="data.installVendor">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                  label="가동일"
                  :disabled="true"
                  name="opertationDate"
                  v-model="data.opertationDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="검사합격번호"
                  name="checkPassNo"
                  v-model="data.checkPassNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                  label="합격일"
                  :disabled="true"
                  name="checkPassDate"
                  v-model="data.checkPassDate"
                  />
              </div>
              <div class="col-6">
                <c-select
                  :editable="editable"
                  codeGroupCd="WO_EQUIP_STOP_CD"
                  :disabled="true"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="discardFlag"
                  label="설비상태"
                  v-model="data.discardFlag"
                ></c-select>
              </div>
              <div class="col-6">
                <c-datepicker
                  :editable="editable"
                  :disabled="true"
                  label="설비상태 변경일"
                  name="discardDate"
                  v-model="data.discardDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card title="점검관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <!-- <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="법정설비여부"
                  name="lawEquipmentFlag"
                  v-model="data.lawEquipmentFlag"
                  @datachange="datachange"
                />
              </div>
              <div class="col-3">
                <c-select
                  :required="data.lawEquipmentFlag == 'Y'"
                  :editable="editable"
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inspectionCycleCd"
                  label="법정점검주기"
                  v-model="data.inspectionCycleCd"
                ></c-select>
              </div> -->
              <!-- <div class="col-3">
                <c-select
                  :editable="false"
                  codeGroupCd="MDM_EQUIP_GRADE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="equipGradeCd"
                  label="설비등급"
                  v-model="data.equipGradeCd"
                ></c-select>
              </div> -->
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="true"
                  :isFlag="true"
                  label="예방점검 여부"
                  name="operFlag"
                  v-model="data.operFlag"
                />
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  :disabled="true"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="selfCycleCd"
                  label="예방점검주기"
                  v-model="data.selfCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :disabled="true"
                  :isFlag="true"
                  label="PM여부"
                  name="pmFlag"
                  v-model="data.pmFlag"
                />
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="MDM_EQUIP_CYCLE_CD"
                  :disabled="true"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="pmCycleCd"
                  label="PM주기"
                  v-model="data.pmCycleCd"
                ></c-select>
              </div>
              <!-- <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="예측정비여부"
                  name="predictiveFlag"
                  v-model="data.predictiveFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="고장정비여부"
                  name="cmFlag"
                  v-model="data.cmFlag"
                />
              </div> -->
              <div class="col-12">
                <c-multi-select
                  :editable="editable"
                  :disabled="true"
                  codeGroupCd="REGULATION_BILL_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="관련 법규"
                  name="relatedLaws"
                  maxValues="3"
                  v-model="data.relatedLaws">
                </c-multi-select>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6" style="margin-top:14px;">
          <c-upload
            :attachInfo="attachInfo"
            :editable="false"
            :isPhoto="true"
            label="설비 사진">
          </c-upload>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
        <div class="popup-bottom-bar">
          <div class="popup-bottom-bar-btngroup">
            <q-btn-group outline >
              <c-btn :flat="true" label="출력" v-if="editable" icon="save_alt" @btnClicked="btnQrCode" />
            </q-btn-group>
          </div>
          <div class="popup-bottom-bar-close">
            <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
          </div>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-info',
  watch: {
    'data.discardFlag': function() {
      if (this.data.discardFlag == 'WESC000001') {
        this.data.discardDate = '';
      }
    }
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentTypeCd: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EQUIPMENT_PHOTO',
        taskKey: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      serverName: '',
      redirectUrl: '',
      saveUrl: transactionConfig.mdm.equipment.info.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      data: {
        plantCd: null,  // 사업장코드
        equipmentTypeCd: null,  // 설비유형 코드
        equipmentCd: '',  // 설비코드
        equipmentNo: '',  // 설비코드
        equipmentName: '',  // 설비명
        mainProcessCd: '',  // 주공정
        subProcessCd: '',  // 부공정
        managementDepts: '',  // 관리부서
        equipmentLocation: '',  // 설비위치
        installDate: '',  // 설치일자
        installVendor: '',  // 설치업체
        opertationDate: '',  // 가동일자
        checkPassNo: '',  // 검사합격번호
        checkPassDate: '',  // 합격일자
        discardFlag: 'WESC000001',
        discardDate: '',  // 폐기일자
        psmFlag: 'N',  // PSM 대상 여부
        amount: '',  // 수량
        lawEquipmentFlag: 'N',  // 법정설비 여부
        inspectionCycleCd: null,  // 법정점검 주기
        selfCycleCd: null,  // 자체점검 주기
        pmCycleCd: null,  // 자체점검 주기
        pmFlag: 'N',  // 예방정비 여부
        cmFlag: 'N',  // 고장정비 여부
        predictiveFlag: 'N',  // 예지정비 여부
        operFlag: 'N', // 운전정비 여부
        relatedLaws: '',  // 관련법규
        recentInspectionDate: '',  // 최근 점검일자
        outsourcingVendor: '',  // 유지보수업체
        hazardousFlag: 'N',
        funcLocationCd: '', // 기능위치
        costCenterCd: '', // 코스트센터
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.redirectUrl = selectConfig.mdm.equipment.qrUrl.url;
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.equipment.get.url;
      this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
      this.checkUrl = selectConfig.mdm.equipment.check.url;
      this.serverUrl();
      this.getDetail();
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    getDetail() {
      if (this.param.equipmentCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {equipmentCd: this.param.equipmentCd, plantCd: this.param.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.param.plantName = _result.data.plantName;
          this.param.plantCd = _result.data.plantCd;
          this.param.equipmentCd = _result.data.equipmentCd;
          this.param.equipmentNo = _result.data.equipmentNo;
          this.param.equipmentTypeCd = _result.data.equipmentTypeCd;
          this.param.equipmentTypeName = _result.data.equipmentTypeName;
          this.param.equipmentName = _result.data.equipmentName;
          this.param.processCds = _result.data.processCds;
          this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
        },);
      }
    },
    reset() {
    },
    saveInfo() {
      if (this.param.equipmentCd) {
        this.saveUrl = transactionConfig.mdm.equipment.info.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.equipment.info.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.equipmentNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.equipmentCd = result.data.equipmentCd;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$emit('insertEquipInfo', result.data.equipmentCd);
      }
      this.$set(this.attachInfo, 'taskKey', this.param.equipmentCd)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.param.equipmentCd = result.data.equipmentCd
      this.getDetail();
    },
    datachange() {
      if (this.data.lawEquipmentFlag === 'N') {
        this.data.inspectionCycleCd = null;
      }
    },
    btnQrCode() {
      let seldata = [];
      seldata.push(this.data)
      this.popupOptions.title = "설비 QR코드"; 
      this.popupOptions.param = {
        selectData: seldata,
      };
      this.popupOptions.target = () => import(`${"./equipmentQr.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '99%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>